import React from "react"
import RealizationInfo from "../../components/Organisms/RealizationInfo/RealizationInfo"
import RealizationPhotos from "../../components/Molecules/realizationPhotos/RealizationPhotos"
import { graphql } from "gatsby"
import photo1 from "../../assets/images/realizations/gizycko/1.jpg"
import photo2 from "../../assets/images/realizations/gizycko/2.jpg"
import lomza from "../../assets/images/realizations/lomza/lomza.jpg"
import preili from "../../assets/images/realizations/preili/preili.jpg"
import bialystok1 from "../../assets/images/realizations/bialystok1/bialystok1-1.jpg"
import OtherRealizations from "../../components/Organisms/OtherRealizations/OtherRealizations"
import CTABanner from "../../components/Organisms/CTABanner/CTABanner"
import { WithIntl } from "../../components/Atoms/withTranslate"
import { localize } from "../../components/Atoms/Language"


const GizyckoPage = ({ data, language }) => {
  const images = [photo1, photo2]
  const otherRealizations = [
    {
      image: lomza,
      name: localize("292"),
      type: localize("296"),
      url: "lomza",
    },
    {
      image: preili,
      name: localize("293"),
      type: localize("296"),
      url: "preili",
    },
    {
      image: bialystok1,
      name: localize("183"),
      type: localize("186"),
      url: "bialystok-1",
    },
  ]

  return (
    <>
      <RealizationInfo closed={4} name={localize("291")} image={data.file.childImageSharp.fixed} language={language}>
        <li className="gray600">{localize("338")}</li>
        <li className="gray600">{localize("331")}</li>
        <li className="gray600">{localize("332")}</li>
        <li className="gray600">{localize("329")}</li>
        <li className="gray600">{localize("333")}</li>
      </RealizationInfo>
      <RealizationPhotos images={images}/>
      <OtherRealizations realizations={otherRealizations} language={language}/>
      <CTABanner language={language}/>
    </>
  )
}

export const query = graphql`
  {
    file(name: { eq: "gizycko" }) {
      childImageSharp {
        fixed(width: 540, height: 400){
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default WithIntl()(GizyckoPage)
